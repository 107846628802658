import React from "react";  

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import helpers from "customs/helpers/helpers";
import axios from 'axios/axios';

import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip } from '@syncfusion/ej2-react-charts';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    let key = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    this.state = {
      key: (key.charAt(key.length - 1) === '#') ? key.substr(0, key.length-1) : key,
      data: null,
      days_between_molts: [],
      number_of_feedings: [],
      error_msg: ''
    }

    this.template = this.chartTemplate;
    this.tooltip = {
        enable: true,
        template: this.template
    };

    this.onChartLoad = this.onChartLoad.bind(this);
  }
  
  chartTemplate = (args) => {
    let feeder = this.state.feedings.data[args.index]['feeder'] ? this.state.feedings.data[args.index]['feeder'] : '';
    return (<div id="templateWrap">
      <table style={{ width: '100%', margin: '5px', border: '1px solid black', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
        <tbody><tr><th colSpan={2}>Days hungry</th></tr>
          <tr><td style={{textAlign: 'right'}}>{args.x}:</td><td style={{textAlign: 'left'}}><b>{args.y}</b></td></tr>
          <tr><td style={{textAlign: 'right'}}>Feeder:</td><td style={{textAlign: 'left'}}><b>{feeder}</b></td></tr>
        </tbody>
      </table>
    </div>);
}

  componentDidMount() {
    if (this.state.key.length === 0 || this.state.key.indexOf('dashboard') >= 0)
      this.setState({error_msg: 'Invalid Key'});
    else {
      helpers.showLoading();
      this.handleFetchTarantulaData();
    }
  }

  handleFetchTarantulaData() {
    axios.get('/' + this.state.key).then(response => this.processFetchTarantulaData(response)).catch(error => this.processErrorAxios(error, null));
  }

  processFetchTarantulaData(response) {
    if (this.isUnmounted) return
    let days_between_molts = this.setDaysBetweenMolts(response.data.molts);
    let number_of_feedings = this.setNumberOfFeedings(response.data.molts);
    let number_of_premolts = this.setNumberOfPremolts(response.data.molts);
    let days_hungry_before_molt = this.setDaysHungryBeforeMolt(response.data.molts);
    try {
      if (number_of_feedings.max_num > days_between_molts.max_num)
        days_between_molts.max_num = number_of_feedings.max_num;
      if (number_of_premolts.max_num > days_between_molts.max_num)
        days_between_molts.max_num = number_of_premolts.max_num;
      if (days_hungry_before_molt.max_num > days_between_molts.max_num)
        days_between_molts.max_num = days_hungry_before_molt.max_num;
    } catch(err) {}
    let feedings = this.setFeedings(response.data.feedings);

    this.setState({data: response.data, days_between_molts, number_of_feedings, number_of_premolts, days_hungry_before_molt, feedings});
    helpers.hideLoading();
  }

  setDaysBetweenMolts(data) {
    let min_year = null;
    let max_year = null;
    let max_num = null;
    let days_between_molts = data.map((prop, key) => {
      let current_date = new Date(prop['date']);
      if (!min_year || min_year > current_date.getFullYear())
        min_year = current_date.getFullYear();
      if (!max_year || max_year < current_date.getFullYear())
        max_year = current_date.getFullYear();
      if (!max_num || max_num < prop['days_previous']) {
        max_num =  (prop['days_previous'] > 10)
          ? Math.ceil(prop['days_previous'] / 50) * 50 : Math.ceil(prop['days_previous'] / 10) * 10;
      }
      return { x: current_date, y: prop['days_previous'] ? prop['days_previous'] : 0 };
    });
    return {data: days_between_molts, min_year, max_year, max_num};
  }

  setNumberOfFeedings(data) {
    let min_year = null;
    let max_year = null;
    let max_num = null;
    let number_of_feedings = data.map((prop, key) => {
      let current_date = new Date(prop['date']);
      if (!min_year || min_year > current_date.getFullYear())
        min_year = current_date.getFullYear();
      if (!max_year || max_year < current_date.getFullYear())
        max_year = current_date.getFullYear();
      if (!max_num || max_num < prop['feeds'].length) {
        max_num =  (prop['feeds'].length > 10)
          ? Math.ceil(prop['feeds'].length / 50) * 50 : Math.ceil(prop['feeds'].length / 10) * 10;
      }
      return { x: current_date, y: prop['feeds'].length };
    });
    return {data: number_of_feedings, min_year, max_year, max_num};
  }

  setNumberOfPremolts(data) {
    let min_year = null;
    let max_year = null;
    let max_num = null;
    let number_of_premolts = data.map((prop, key) => {
      let current_date = new Date(prop['date']);
      if (!min_year || min_year > current_date.getFullYear())
        min_year = current_date.getFullYear();
      if (!max_year || max_year < current_date.getFullYear())
        max_year = current_date.getFullYear();
      if (!max_num || max_num < prop['days_premolt']) {
        max_num =  (prop['days_premolt'] > 10)
          ? Math.ceil(prop['days_premolt'] / 50) * 50 : Math.ceil(prop['days_premolt'] / 10) * 10;
      }
      return { x: current_date, y: prop['days_premolt'] ? prop['days_premolt'] : 0 };
    });
    return {data: number_of_premolts, min_year, max_year, max_num};
  }

  setDaysHungryBeforeMolt(data) {
    let min_year = null;
    let max_year = null;
    let max_num = null;
    let days_hungry_before_molt = data.map((prop, key) => {
      let current_date = new Date(prop['date']);
      if (!min_year || min_year > current_date.getFullYear())
        min_year = current_date.getFullYear();
      if (!max_year || max_year < current_date.getFullYear())
        max_year = current_date.getFullYear();
      if (!max_num || max_num < prop['days_hungry']) {
        max_num =  (prop['days_hungry'] > 10)
          ? Math.ceil(prop['days_hungry'] / 50) * 50 : Math.ceil(prop['days_hungry'] / 10) * 10;
      }
      return { x: current_date, y: prop['days_hungry'] ? prop['days_hungry'] : 0 };
    });
    return {data: days_hungry_before_molt, min_year, max_year, max_num};
  }

  setFeedings(data) {
    let min_year = null;
    let max_year = null;
    let max_num = null;
    let feedings = data.map((prop, key) => {
      let current_date = new Date(prop['date']);
      if (!min_year || min_year > current_date.getFullYear())
        min_year = current_date.getFullYear();
      if (!max_year || max_year < current_date.getFullYear())
        max_year = current_date.getFullYear();
      if (!max_num || max_num < prop['days_hungry']) {
        max_num =  (prop['days_hungry'] > 10)
          ? Math.ceil(prop['days_hungry'] / 50) * 50 : Math.ceil(prop['days_hungry'] / 10) * 10;
      }
      return { x: current_date, y: prop['days_hungry'] ? prop['days_hungry'] : 0, text: '', feeder: prop['feeder'] ? prop['feeder'] : 0 };
    });
    return {data: feedings, min_year, max_year, max_num};
  }

  processErrorAxios(error, prop) {
    if (this.isUnmounted) return
    if (prop !== null) this.setState({prop: []});
    var default_err = helpers.getErrorMessage(error);
    this.setState({data: null, error_msg: default_err === false ? 'An error has occured, please try again.' : default_err});
    helpers.hideLoading();
  }

  onChartLoad(chart_id) {
    let chart = document.getElementById(chart_id);
    if (chart) chart.setAttribute('title', '');
  }

  componentWillUnmount() {
      this.isUnmounted = true;
  }

  render() {
    const SAMPLE_CSS = `.control-fluid {padding: 0px !important;} .charts {align :center}`;
    const { classes } = this.props;
    const details = this.state.data
      ? this.state.data.details.genus + ' ' + this.state.data.details.species
        + (this.state.data.details.common_name ?'<br>(' + this.state.data.details.common_name + ')' : '')
        + (this.state.data.details.country ?'<br>Country: ' + this.state.data.details.country : '')
        + (this.state.data.details.type ?'<br>Type: ' + this.state.data.details.type : '')
        + (this.state.data.details.sex ?'<br>Sex: ' + this.state.data.details.sex : '')
        + (this.state.data.details.temperament ?'<br>Temperament: ' + this.state.data.details.temperament : '')
        + (this.state.data.details.venom ?'<br>Venom: ' + this.state.data.details.venom : '')
        + (this.state.data.details.category ?'<br>Category: ' + this.state.data.details.category : '')
      : '';
    const feeding_max_num = this.state.data ? this.state.feedings.max_num : 5;
    return (
      this.state.data
      ? 
      <GridContainer>
        <GridItem xs={12} md={12}>
          <SnackbarContent
              message={"This is a BETA release.<br>If you have requests or suggestions, please send an email to exotikeeper@p3kb.com."}
              color="warning"
            />
        </GridItem>
        <style>{SAMPLE_CSS}</style>
        <GridItem xs={12} md={4}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <DashboardIcon/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}><b>{this.state.data.details.name ? this.state.data.details.name : ' '}</b></h4>
            </CardHeader>
            <CardBody>
              <h5>{helpers.addLineBreaks(details)}</h5>
            </CardBody>
          </Card>
        </GridItem>
        {
          this.state.days_between_molts.data.length > 0
          ? <GridItem xs={12} md={8}>
              <Card>
                <ChartComponent id='charts1' style={{ textAlign: "center" }} primaryXAxis={{
                  valueType: 'DateTime',
                  labelFormat: 'y-MM-dd',
                  intervalType: 'Months',
                  edgeLabelPlacement: 'Shift',
                  majorGridLines: { width: 0 }
                }}
                load={this.load} primaryYAxis={{
                  labelFormat: '{value}',
                  rangePadding: 'None',
                  minimum: 0,
                  maximum: this.state.days_between_molts.max_num,
                  interval: Math.ceil(this.state.days_between_molts.max_num / 10),
                  lineStyle: { width: 0 },
                  majorTickLines: { width: 0 },
                  minorTickLines: { width: 0 }
                }}
                chartArea={{
                  border: { width: 0 }
                }}
                tooltip={{ enable: true }}
                width={'90%'}
                title='Molting History' loaded={this.onChartLoad('charts1')}>
                  <Inject services={[LineSeries, DateTime, Legend, Tooltip]}/>
                  <SeriesCollectionDirective>
                    <SeriesDirective dataSource={this.state.days_between_molts.data} xName='x' yName='y'
                      name='Days from previous molt'
                      width={2} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                    </SeriesDirective>
                    <SeriesDirective dataSource={this.state.days_hungry_before_molt.data} xName='x' yName='y'
                      name='Days Hungry'
                      width={2} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                    </SeriesDirective>
                    <SeriesDirective dataSource={this.state.number_of_feedings.data} xName='x' yName='y'
                      name='Number of feedings'
                      width={2} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                    </SeriesDirective>
                    {
                      this.state.number_of_premolts.max_num === 0
                      ? ''
                      : <SeriesDirective dataSource={this.state.number_of_premolts.data} xName='x' yName='y'
                          name='Days premolt'
                          width={2} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                    }
                  </SeriesCollectionDirective>
                </ChartComponent>
              </Card>
            </GridItem>
          : ''
        }
        {
          this.state.feedings.data.length > 0
          ?  <GridItem xs={12} md={12}>
              <Card>
                <ChartComponent id='charts3' style={{ textAlign: "center" }} primaryXAxis={{
                  valueType: 'DateTime',
                  labelFormat: 'y-MM-dd',
                  intervalType: 'Days',
                  edgeLabelPlacement: 'Shift',
                  majorGridLines: { width: 0 }
                }}
                load={this.load} primaryYAxis={{
                  labelFormat: '{value}',
                  rangePadding: 'None',
                  minimum: 0,
                  maximum: feeding_max_num,
                  interval: Math.ceil(feeding_max_num / 10),
                  lineStyle: { width: 0 },
                  majorTickLines: { width: 0 },
                  minorTickLines: { width: 0 }
                }}
                chartArea={{
                  border: { width: 0 }
                }}
                tooltip={this.tooltip}
                width={'90%'}
                title='Feed History' loaded={this.onChartLoad('charts3')}>
                  <Inject services={[LineSeries, DateTime, Legend, Tooltip]}/>
                  <SeriesCollectionDirective>
                    <SeriesDirective dataSource={this.state.feedings.data} xName='x' yName='y'
                      width={2} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                    </SeriesDirective>
                  </SeriesCollectionDirective>
                </ChartComponent>
              </Card>
            </GridItem>
          : ''
        }
      </GridContainer>
      : <div className='control-pane'>{this.state.error_msg}</div>
    );
  }
}

export default withStyles(dashboardStyle)(Dashboard);