import React from "react";

const helpers = {
    showLoading: function() {
        document.getElementById('loading').classList.add('loading-active');
    },
    hideLoading: function() {
        document.getElementById('loading').classList.remove('loading-active');
    },
    addLineBreaks: function(string) {
        return string.split('<br>').map((text, index) => (
            <React.Fragment key={`${index}`}>
              {text}
              <br />
            </React.Fragment>
          ));
    },
    getErrorMessage: function(error) {
        var default_err = 'An error has occured, please try again.';
        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                const result = error.response.data.errors;
                var arr = [];
                if (result) {
                    Object.keys(result).forEach(function(key, val) {
                        if (result[key] && result[key][0]) arr.push(result[key][0]);
                    });
                } else arr.push(default_err);
                default_err = arr.join('<br>');
            } else if (error.response.data.status === 'Token is Invalid' || error.response.data.status === 'Token is Expired'
                || error.response.data.status === 'Authorization Token not found') {
                return false;
            }
        }
        return default_err;
    }
}

export default helpers;