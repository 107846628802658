import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'canvas-toBlob';

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.jsx";

import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";

import { createStore, applyMiddleware } from 'redux';
import rootReducer from './store/reducers/rootReducer';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import "@fortawesome/fontawesome-free/css/all.css"

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/admin/dashboard/" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
