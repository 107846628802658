import * as authActionTypes from '../actions/authActionTypes';
import helpers from "customs/helpers/helpers";

const initState = {
    data: {
        token: null,
        user: null,
        authMessage: '',
        authStatus: '',
        resetLinkMessage: '',
        resetPasswordMessage: ''
    }
};

const executeLoginSuccess = (state, action) => {
    return {
        ...state,
    }
}

const executeLoginError = (state, action) => {
    state.data.authMessage = 'Invalid Credentials';
    return {
        ...state,
    }
}

const executeDefault = (state, action) => {
    return {
        ...state,
    }
}

const executeLogout = (state, action) => {
    return {
        ...state,
    }
}

const executeUpdateProfileSuccess = (state, action) => {
    state.data.authMessage = 'Profile updated';
    return {
        ...state,
    }
}

const executeUpdateProfileError = (state, action) => {
    var err = helpers.getErrorMessage(action);
    if (err === false)
        state = initState
    else {
        state.data = action.props.auth;
        state.data.authMessage = err;
    }
    return {
        ...state,
    }
}

const executeChangePasswordSuccess = (state, action) => {
    state.data.authMessage = 'Password changed';
    return {
        ...state,
    }
}

const executeChangePasswordError = (state, action) => {
    var err = helpers.getErrorMessage(action);
    if (err === false)
        state = initState
    else {
        state.data = action.props.auth;
        state.data.authMessage = err;
    }
    return {
        ...state,
    }
}

const executeSendResetEmailSuccess = (state, action) => {
    state.data.resetLinkMessage = state.data && state.data.status ? state.data.status : 'We have sent your password reset link! Please check your e-mail.';
    state.data.authStatus = '#4caf50';
    return {
        ...state,
    }
}

const executeSendResetEmailError = (state, action) => {
    state.data.resetLinkMessage = state.data && state.data.error ? state.data.error : 'Unable to send password reset link to your e-mail.';
    state.data.authStatus = 'red';
    return {
        ...state,
    }
}

const executeResetPasswordSuccess = (state, action) => {
    state.data.resetPasswordMessage = state.data && state.data.status ? state.data.status : 'Password successfully changed. Please login with your new password.';
    state.data.authStatus = '#4caf50';
    return {
        ...state,
    }
}

const executeResetPasswordError = (state, action) => {
    state.data.resetPasswordMessage = state.data && state.data.error ? state.data.error : 'The password reset token is invalid or has expired.';
    state.data.authStatus = 'red';
    return {
        ...state,
    }
}

const executeUpdateProfileData = (state, action) => {
    return {
        ...state,
    }
}

const authReducer = (state = initState, action) => {
    if (action.response && action.response.data) state.data = action.response.data;
    initState.data.authMessage = '';
    state.data.authMessage = '';
    switch (action.type) {
        case authActionTypes.LOGIN_SUCCESS:
            return executeLoginSuccess(state, action);
        case authActionTypes.LOGIN_ERROR:
            return executeLoginError(state, action);
        case authActionTypes.DO_LOGOUT:
            return executeLogout(initState, action);
        case authActionTypes.UPDATE_PROFILE_SUCCESS:
            return executeUpdateProfileSuccess(state, action);
        case authActionTypes.UPDATE_PROFILE_ERROR:
            return executeUpdateProfileError(state, action);
        case authActionTypes.CHANGE_PASSWORD_SUCCESS:
            return executeChangePasswordSuccess(state, action);
        case authActionTypes.CHANGE_PASSWORD_ERROR:
            return executeChangePasswordError(state, action);
        case authActionTypes.SEND_RESET_EMAIL_SUCCESS:
            return executeSendResetEmailSuccess(state, action);
        case authActionTypes.SEND_RESET_EMAIL_ERROR:
            return executeSendResetEmailError(state, action);
        case authActionTypes.RESET_PASSWORD_SUCCESS:
            return executeResetPasswordSuccess(state, action);
        case authActionTypes.RESET_PASSWORD_ERROR:
            return executeResetPasswordError(state, action);
        case authActionTypes.UPDATE_PROFILE_DATA:
            return executeUpdateProfileData(state, action);

        default:
            return executeDefault(state, action);
    }
}

export default authReducer