import Dashboard from "views/Dashboard/Dashboard.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

let key = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
if (key.charAt(key.length - 1) === '#') key.substr(0, key.length-1);

var dashRoutes = [
  {
    path: "/dashboard/" + key,
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
];
export default dashRoutes;
